import React from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { HashLink } from 'react-router-hash-link';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';


const MobileMenu = ({theme}) => {
    const [state, setState] = React.useState({
        left: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };
    
      const list = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
         
        >
          
          <div className="mobile_menu_link_container">
          {/* <a 
          className='mobile_menu_link' 
          href="#home" 
          >
          Home
          </a> */}
          <HashLink 
          className='mobile_menu_link' 
          // href="#home" 
          smooth 
            to="/#home"
          >
          Home
          </HashLink>
            <HashLink 
            className='mobile_menu_link' 
            // href="#portfolio" 
            smooth 
            to="/#portfolio"
            >
            Portfolio
            </HashLink>
            <HashLink 
            className='mobile_menu_link' 
            // href="#about" 
            smooth 
            to="/#about"
            >
            About
            </HashLink>
            <HashLink 
            className='mobile_menu_link' 
            // href="#services" 
            smooth 
            to="/#services"
            >
            Services
            </HashLink>
            <HashLink 
            className='mobile_menu_link' 
            // href="#skills" 
            smooth 
            to="/#skills"
            >
            Skills
            </HashLink>
            <HashLink className='mobile_menu_link' 
            // href="#blog" 
            smooth 
            to="/#blog"
            >
            Blogs
            </HashLink>
            <HashLink className='mobile_menu_link' 
            // href="#contact" 
            smooth 
            to="/#contact"
            >
            Contact
            </HashLink>
            </div>
        </Box>
      );
    

  return (
    <div>{
        <React.Fragment key={"left"} >
          <Button onClick={toggleDrawer("left", true)} className={theme=="light"?"blackColor":"whiteColor"} ><MenuOpenIcon fontSize="xl"/></Button>
          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
        </React.Fragment>
      }</div>
  )
}

export default MobileMenu