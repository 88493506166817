import s1 from "../utility/images/1.svg";
import s2 from "../utility/images/2.svg";
import s3 from "../utility/images/3.svg";
import s4 from "../utility/images/4.svg";
import s5 from "../utility/images/5.svg";
import s6 from "../utility/images/6.svg";

const SData = [
  {
    img: s1,
    topic: "AI-Enhanced Experiences",
    desc: "Transform digital media with captivating AI-integrated apps.",
  },
  {
    img: s2,
    topic: "Custom Website Development",
    desc: "Craft user-friendly websites with cutting-edge tech.",
  },
  {
    img: s3,
    topic: "Full-Stack Optimization",
    desc: "Optimize front-end and back-end for top performance.",
  },
  {
    img: s4,
    topic: "Next-Level Commerce Solutions",
    desc: "Elevate retail experiences with responsive design.",
  },
  {
    img: s5,
    topic: "Uncompromising Quality Assurance",
    desc: "Deliver flawless products with rigorous quality checks.",
  },
  {
    img: s6,
    topic: "Consultation and Tech Support",
    desc: "Offer expert guidance for seamless project implementation.",
  },
];

export default SData;
