import lead_tracker from "./imgs/lead_tracker.jpg";
import weather_app from "./imgs/weather_app.jpg";
import ecomm from "./imgs/ecomm.gif";
import mi_clone from "./imgs/mi_clone.gif";
import social_media from "./imgs/social-media.gif";
import resume from "./imgs/resume.jpg";
import lyriks_music from "./imgs/lyriks_music.gif";
import easybazaar_native from "./imgs/easybazaar_native.gif";
import todo_native from "./imgs/todo_native.gif";

let i = 1;

const Menu = [
  {
    id: i++,
    image: easybazaar_native,
    name: "Easy-Bazaar (Android App)",
    category: "Android",
    url: "https://github.com/rahul-612/easybazaar_native",
    description:"Easy-Bazaar: Shop hassle-free on Android. Vast selection, secure transactions, personalized recommendations.",
  },
  {
    id: i++,
    image: ecomm,
    name: "Easy-Bazaar",
    category: "MERN",
    url: "https://github.com/rahul-612/MERN-Ecommerce",
    description:"Easy-Bazaar: One-stop web app solution for seamless online shopping, offering a diverse range of products, secure transactions, and convenient browsing.",
  },
  {
    id: i++,
    image: lyriks_music,
    name: "TubeTown Music Player",
    category: "ReactJS",
    url: "https://github.com/rahul-612/tubetown-music-app",
    description:"An intuitive web-based music player delivering seamless playback and personalized playlists."
  },
  {
    id: i++,
    image: todo_native,
    name: "Todo App",
    category: "Android",
    url: "https://github.com/rahul-612/todo_native",
    description:"A feature-rich Android app for organizing tasks, setting reminders, and boosting productivity on-the-go."
  },
  {
    id: i++,
    image: social_media,
    name: "Social Media",
    category: "MERN",
    url: "https://github.com/rahul-612/Social-media",
    description:"A dynamic web platform fostering connections, sharing experiences, and sparking conversations across diverse communities."
  },
  {
    id: i++,
    image: mi_clone,
    name: "Mi Store Clone",
    category: "ReactJS",
    url: "https://mi-store-react-clone.netlify.app/",
    description:"A visually appealing web platform offering a seamless shopping experience with a wide range of products, exclusive deals, and user-friendly navigation, inspired by the MI Store."
  },
  {
    id: i++,
    image: lead_tracker,
    name: "Lead Tracker",
    category: "JavaScript",
    url: "https://github.com/rahul-612/Lead-Tracker-Chrome-Extension-",
    description:"A streamlined Chrome extension designed to effortlessly capture and store URLs, simplifying lead tracking and organization for efficient follow-ups."
  },

  {
    id: i++,
    image: weather_app,
    name: "Live Weather",
    category: "ReactJS",
    url: "https://github.com/rahul-612/Live-Weather-App",
    description:"A sleek weather web app providing accurate forecasts."
  },
];

export default Menu;
