import React, { useEffect, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import "./home.css";
import "./home_res.css";
import { useInView } from "react-intersection-observer";
import Typewriter from "typewriter-effect";
import Portfolio from "../Portfolio/Portfolio.jsx"
import About from "../About/About.jsx"
import Services from "../Services/Services.jsx"
import Skills from "../Skills/Skills.jsx"
import Contact from "../Contact/Contact.jsx"
import Blog from "../Blog/Blog.jsx"
import {
  useMotionTemplate,
  useMotionValue,
  motion,
  animate,
} from "framer-motion";
import { Stars } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { HashLink } from "react-router-hash-link";
const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

const Home = () => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const heroRef = useRef(null);

  useEffect(() => {
    const updateMousePosition = (ev) => {
      if (!heroRef.current) return;
      const { clientX, clientY } = ev;
      heroRef.current.style.setProperty("--x", `${clientX}px`);
      heroRef.current.style.setProperty("--y", `${clientY}px`);
    };

    window.addEventListener("mousemove", updateMousePosition);

    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  // NOTE: TO WANT RADIAL BALL JUST UNCOMMENT background-image FROM .hero

  const color = useMotionValue(COLORS_TOP[0]);

  useEffect(() => {
    animate(color, COLORS_TOP, {
      ease: "easeInOut",
      duration: 10,
      repeat: Infinity,
      repeatType: "mirror",
    });
  }, []);

  const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;
  const border = useMotionTemplate`1px solid ${color}`;
  const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;

  return (
    <>
      <motion.section
      style={{
        backgroundImage,
      }}
      className="hero aura-background relative grid min-h-screen  overflow-hidden bg-gray-950 px-4 py-12 text-gray-200" id="home" ref={heroRef}>
        {inView ? <Navbar theme="dark" /> : <Navbar theme="light" />}
        <div className="homeHead flex">
          <h1>Hello, I'm</h1> <span>Rahul Kumar</span>{" "}
          <strong>
            <Typewriter
              options={{
                strings: [
                  "A Full Stack Developer.",
                  "From front-end finesse to back-end brilliance.",
                  "Build bridges between users and technology.",
                  "Create digital worlds with keystrokes.",
                  // "I love to code.",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </strong>
          <div className="scrollBtn">
            <HashLink smooth to="/#portfolio">
              <span ref={ref}></span>
            </HashLink>
          </div>
        </div>
        <div className="absolute inset-0 z-0">
        <Canvas>
          <Stars radius={150} count={2500} factor={4} fade speed={2} />
        </Canvas>
      </div>
      </motion.section>
      <Portfolio />
      <About />
      <Services />
      <Skills />
      <Blog />
      <Contact />
    </>
  );
};

export default Home;
