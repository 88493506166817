import React, { useState } from "react";
import "./navbar.css";
import "../Footer/footer.css";
import MobileMenu from "./MobileMenu.jsx";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";

const Navbar = ({ theme }) => {
  const { section } = useSelector((state) => state.user);
  return (
    <>
      <nav className={theme === "light" ? "lightNav  flex" : "navBar flex"}>
        <div className="navMenu flex">
          {/* <a
            className={
              theme === "light" ? "lightLink homeNav" : "navLink homeNav"
            }
            href="#home"
            onClick={active}
          >
            Home
          </a> */}
          {theme === "dark" && (
            <HashLink
              className={
                theme === "light" ? "lightLink homeNav" : "navLink homeNav"
              }
              // href="#home"
              smooth
              to="/#home"
              onClick={active}
            >
              Home
            </HashLink>
          )}
          <HashLink
            className={
              theme === "light" ? "lightLink portNav" : "navLink portNav"
            }
            // href="#portfolio"
            smooth
            to="/#portfolio"
            onClick={active}
          >
            Portfolio
          </HashLink>
          <HashLink
            className={
              theme === "light" ? "lightLink aboutNav" : "navLink aboutNav"
            }
            // href="#about"
            smooth
            to="/#about"
            onClick={active}
          >
            About
          </HashLink>
          <HashLink
            className={
              theme === "light" ? "lightLink serviceNav" : "navLink serviceNav"
            }
            // href="#services"
            smooth
            to="/#services"
            onClick={active}
          >
            Services
          </HashLink>
          {theme==='light'&&<div
            className="footerLogo footerChild serviceNav"
            style={{ marginBottom: "0",marginLeft:'8vmin' }}
          >
            <HashLink smooth style={{ color: "black",fontSize:'3vmin' }} to="/#home">
              Rahul<span>.</span>
            </HashLink>
          </div>}
          <HashLink
            className={
              theme === "light" ? "lightLink skillNav" : "navLink skillNav"
            }
            // href="#skills"
            smooth
            to="/#skills"
            onClick={active}
          >
            Skills
          </HashLink>
          <HashLink
            className={
              theme === "light" ? "lightLink contactNav" : "navLink contactNav"
            }
            // href="#blog"
            smooth
            to="/#blog"
            onClick={active}
          >
            Blogs
          </HashLink>
          <HashLink
            className={
              theme === "light" ? "lightLink contactNav" : "navLink contactNav"
            }
            // href="#contact"
            smooth
            to="/#contact"
            onClick={active}
          >
            Contact
          </HashLink>
        </div>
        <div className="mobile_menu">
          <div
            className="mobile_home"
            style={theme === "light" ? { opacity: "1" } : { opacity: "0" }}
          >
            <HashLink
              // href="#home"
              smooth
              to="/#home"
            >
              Rahul<span>.</span>
            </HashLink>
          </div>
          <div className="mobile_menu_container">
            <MobileMenu theme={theme} />
          </div>
        </div>
      </nav>
    </>
  );
};

function active(e) {
  // console.log(e);
  let btns = document.querySelectorAll(".lightLink");
  btns.forEach((elem) => {
    // console.log(elem.classList);
    elem.classList.remove("active");
  });
  if (e.target.className === "active") {
  } else {
    e.target.classList.add("active");
  }
}

export default Navbar;
