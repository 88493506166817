import React from 'react'
import "./lines.css"
const Lines = () => {
  return (
   <>
    <div className="lines-wrap"><div className="lines-inner"><div className="lines"></div></div></div>
   </>
  )
}

export default Lines