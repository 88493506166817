import React, { useState } from "react";
import "./portfolio.css";
import "./portfolio_res.css";
import Menu from "./Menu";
import Category from "./Category";
import MenuItems from "./MenuItems";
import { useInView } from "react-intersection-observer";
// import Lines from "../utility/lines/Lines";
import {
  useMotionTemplate,
  useMotionValue,
  motion,
  animate,
} from "framer-motion";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import underlineImg from "../utility/images/heading_underline.webp"

const allCatValues = [
  "all",...new Set(
    Menu.map((currElem) => {
      return currElem.category;
    })
  )
  
];

const Portfolio = () => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: true,
  });

  const [items, setItems] = useState(Menu);
  const [catItems, setCatItems] = useState(allCatValues);

   const [hoveredCard, setHoveredCard] = useState(null);

  const filterItem = (category) => {
    if (category === "all") {
      setItems(Menu);
      return;
    }

    const updatedItems = Menu.filter((currElem) => {
      return currElem.category === category;
    });

    setItems(updatedItems);
  };

  return (
    <>
      <section id="portfolio">
        {/* <Lines /> */}
        <div className="portHead">
          <h1 className={inView === true ? "portHeadAni flex flex-col" : ""}>
            <span>Portfolio</span>
            <span className="heading_underline">
            <img src={underlineImg} alt="" />
        </span>
          </h1>
         
          <Category filterItem={filterItem} catItems={catItems} />
        </div>

        <div className="projectContainer" ref={ref} style={{ overflowY: 'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none', WebkitOverflowScrolling: 'touch' }}>
          {items.map((elem, index) => {
            const { id, name, image, url,description } = elem; //oject destructuring

            
               {/* return (
              <a href={url} target="_blank" className="projectCard" key={index} style={inView?{opacity:'1'}:{opacity:'0'}}>
               
                <div className={inView === true ? "cardOuterAni" : ""}></div>
                <div className={inView === true ? "cardAniInfo" : ""}>
                  <img src={image} alt={name} className="p_img" />
                  <h1 className="p_title">{name}</h1>
                </div>
               
              </a>
            );  */}
            
            
            return (
              <motion.div
                key={index}
                className="border-2 text-4xl relative overflow-hidden m-[4vmin] rounded-[1rem]"
                style={{
                  opacity: inView ? "1" : "0",
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "0",
                  width: "45vmin",
                  height: "45vmin",
                }}
                onMouseEnter={() => setHoveredCard(index)} // Set hovered card index when mouse enters card
                onMouseLeave={() => setHoveredCard(null)} // Reset hovered card index when mouse leaves card
              >
                <div className="flex flex-col justify-center bg-black p-6 w-full" style={{alignItems:'start'}}>
                  <h3 className="mb-2 text-2xl font-semibold text-white">
                    {name}
                  </h3>
                  <p className="mb-2 text-xl text-slate-300">
                   {description}
                  </p>
                </div>
                <motion.img
                  className="absolute inset-0 w-full h-full object-cover"
                  animate={
                    hoveredCard === index // Apply animation only to the hovered card
                      ? {
                          position: "relative",
                          inset: "auto",
                          width: "50%",
                          height: "auto",
                        }
                      : {}
                  }
                  src={image}
                />
                <a
                  href={url}
                  target="_blank"
                  className="w-1/2 flex items-center mb-2 text-2xl font-semibold text-white underline hover:text-[#d63447]"
                >
                  More <ArrowOutwardIcon/>
                </a>
              </motion.div>
            );

          })}
        </div>

        {/* <Project/> */}
      </section>
    </>
  );
};

export default Portfolio;
